import React from 'react'

import RequiredMarker from './required_marker'

type Props = {
  label: string
  label_for?: string
  class_name?: string
  label_icon?: any
  label_info?: string
  hidden?: boolean
  disabled?: boolean
  required?: boolean
}

const SimpleLabel = (props: Props): JSX.Element | null => {
  if (props.hidden) {
    return null
  }

  return (
    <div>
      <label htmlFor={props.label_for} className={props.class_name}>
        {props.label}:
      </label>
      {' '}
      <RequiredMarker hidden={!props.required} />
    </div>
  )
}

export default SimpleLabel

// Used to generate unique keys and/or ids.

const counter = {}

// Generates a unique ID or KEY. If prefix is given, the ID is appended to it.
function unique_key (prefix = 'default') {
  if (!counter[prefix]) {
    counter[prefix] = 0
  }

  const key = ++counter[prefix]

  if (prefix === 'default') {
    return `${key}`
  }

  return `${prefix}${key}`
}

export default unique_key

import React from 'react'

import SimpleLabel from './simple_label'
import FeedbackBox from './feedback_box'
import unique_key from '@config/unique_key'

type Props = {
  id?: string
  name?: string
  value?: string
  field: string
  label: string
  placeholder?: string
  hidden?: boolean
  disabled?: boolean
  mandatory?: boolean
  info?: string
  missing?: boolean
  error: string
  onChange?: (field: string, value: string) => void
  onBlur?: (name: string, value: string) => void
}

const SimplePasswordField = (props: Props): JSX.Element | null => {
  if (props.hidden) {
    return null
  }

  let cssClass = 'form-control'

  if (props.missing) {
    cssClass = `${cssClass} missing_mandatory`
  }

  const field_id = props.id || unique_key(props.field)

  return (
    <div className="single_fieldset_boundary">
      <SimpleLabel label_for={field_id} label={props.label} required={props.mandatory} />
      <div>
        <input
          id={field_id}
          name={props.name}
          type="password"
          onChange={e => props.onChange?.(props.field, e.target.value)}
          onBlur={e => props.onBlur?.(props.field, e.target.value)}
          disabled={props.disabled}
          placeholder={props.placeholder}
          className={cssClass}
        />
      </div>
      <FeedbackBox message={props.error} />
    </div>
  )
}

export default SimplePasswordField

import React from 'react'

import BrandLoginLogo from './brand_login_logo'

import SessionForm from './form'

type Props = {
  client_logo: boolean
}

class SessionNew extends React.Component<Props> {
  render (): JSX.Element {
    return (
      <div className="container">
        <div className="login-container">
          <BrandLoginLogo hidden={!this.props.page['client_logo_enabled?']} />
          <SessionForm form={this.props.page} translation={this.props.translation} />
        </div>
      </div>
    )
  }
}

export default SessionNew

import React from 'react'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  id?: string
  label: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'link'
  size?: 'small' | 'large'
  hidden?: boolean
  disabled?: boolean
  form_action?: string
  class_name?: string
}

const sizes = {
  small: 'btn-sm',
  large: 'btn-lg'
}

const variants = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  success: 'btn-success',
  danger: 'btn-danger',
  warning: 'btn-warning',
  link: 'btn-link'
}

const SimpleButton = (props: Props): JSX.Element | null => {
  if (props.hidden) {
    return null
  }

  const variant = props.variant ? variants[props.variant] : 'btn-primary'

  const css_size = props.size ? sizes[props.size] : undefined
  const css_class = props.class_name

  const style = ['btn', variant, css_size, css_class].filter(Boolean)

  return (
    <button
      id={props.id}
      type={props.type ?? 'button'}
      className={style.join(' ')}
      disabled={props.disabled}
      onClick={e => props.onClick?.(e)}
      formAction={props.form_action}
    >{props.label}</button>
  )
}

export default SimpleButton

import React from 'react'

type Props = {
  name?: string
  hidden?: boolean
  dynamic?: boolean
  message: string
}

const FeedbackBox = (props: Props): JSX.Element | null => {
  if (props.hidden) {
    return null
  }

  if (props.message.length === 0) {
    return null
  }

  return (
    <small className="error">{props.message}</small>
  )
}

export default FeedbackBox

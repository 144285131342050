import React from 'react'

import SimpleForm from '@elements/simple_form'

import FlashMessage from '@elements/flash_message'
import SimpleTextField from '@elements/simple_text_field'
import SimplePasswordField from '@elements/simple_password_field'
import SimpleButton from '@elements/simple_button'
import Separator from '@elements/separator'

import HTTPFetch from '@config/http_fetch'

type Props = {
  form: any
}

class SessionForm extends React.Component<Props> {
  state = {
    flash_message: ''
  }

  render (): JSX.Element {
    return (
      <div className="login-box">
        <SimpleForm id="form" method={this.props.form.form_method}>
          <FlashMessage type="error" content={this.state.flash_message} />
          <SimpleTextField
            name="user[login]"
            field="login"
            label={this.props.translation.username}
            autofocus={true}
            error=""
          />
          <SimplePasswordField
            name="user[password]"
            field="password"
            label={this.props.translation.password}
            error=""
          />
          <SimplePasswordField
            hidden={!this.props.form['allow_db_auth_with_pin?']}
            name="user[pin]"
            field="pin"
            label={this.props.translation.pin}
            error=""
          />
          <div className="single_fieldset_boundary">
            <SimpleButton
              form_action={this.props.form.form_action}
              type="submit"
              label={this.props.translation.buttons.submit}
              onClick={this.handleSubmit.bind(this)}
            />
            <Separator />
            <SimpleButton type="reset" label={this.props.translation.buttons.reset} />
          </div>
          <div className="">
            <SimpleButton
              form_action={this.props.form.reset_secret_path}
              hidden={!this.props.form['allow_reset_secret?']}
              type="submit"
              variant="link"
              class_name="link-info"
              label={this.props.translation.buttons.forgot_secret}
              onClick={this.handleSubmit.bind(this)}
            />
          </div>
        </SimpleForm>
      </div>
    )
  }

  handleSubmit (event: any) {
    event.preventDefault()

    const action = event.target.formAction

    const form = document.getElementById('form')

    const data = new FormData(form)

    HTTPFetch.post(action, data).then(response => {
      if (response.data) {
        window.location.replace(response.headers.location)
      } else {
        this.setState({ flash_message: 'Unknown error' })
      }
    }).catch(failure => {
      const response = failure.response
      if (response?.data) {
        this.setState({ flash_message: response.data.message })
      } else {
        this.setState({ flash_message: failure.message })
      }
    })
  }
}

export default SessionForm

// mount react components

import React from 'react'
import DOMClient from 'react-dom/client'

const UIManager = {
  active_component: null,
  active_spec_id: null,
  prerender: null,
  production: null,
  registered_components: {},

  render () {
    if (!this.active_component) {
      if (!this.production) {
        console.warn('There is no active component')
      }

      return
    }

    const component = this.registered_components[this.active_component]

    if (!component) {
      throw new Error(`Unknown ${this.active_component} constant, it may not be registered.`)
    }

    const spec = document.getElementById(this.active_spec_id)

    const props = JSON.parse(spec.textContent)

    const parent = spec.parentElement

    const container = parent.insertBefore(document.createElement('div'), spec)

    const root = DOMClient.createRoot(container!)

    // React.createElement(component, props, null),
    root.render(React.createElement(component, props))
  },

  activate (component: string, spec_id: string, prerender: boolean, production: boolean) {
    this.active_component = component
    this.active_spec_id = spec_id
    this.prerender = prerender
    this.production = production
    // initialize page
    this.initialize()
  },

  register (components) {
    Object.keys(components).forEach((key) => {
      this.registered_components[key] = components[key]
    })
  },

  initialize () {
    document.addEventListener('DOMContentLoaded', function (_event) {
      // context.UIManager.render()
      UIManager.render()
    })
  }
}

function getContext () {
  if (typeof window !== 'undefined') {
    return window
  }

  return this
}

const context = getContext()

if (context === undefined) {
  throw new Error("The context (usually Window or NodeJS's Global) is undefined.")
}

// share same ui manager from different js files
if (typeof context.UIManager === 'undefined') {
  context.UIManager = UIManager
}

export default context.UIManager

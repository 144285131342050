import React from 'react'

type Props = {
  hidden?: boolean
}

const RequiredMarker = (props: Props): JSX.Element | null => {
  if (props.hidden) {
    return null
  }

  return (
    <span className="mandatory_marker">*</span>
  )
}

export default RequiredMarker

// axios wrapper for some global configuration. If we want to change to the
// fetch api we just change here.

import axios from 'axios'

const csrf_token = (): string | null => {
  const token = document.querySelector('meta[name="csrf-token"]')

  if (token == null) {
    return ''
  }

  return token.getAttribute('content')
}

const HTTPFetch = axios.create({
  headers: { Accept: 'application/json' },
  responseType: 'json',
  timeout: 60000
})

HTTPFetch.interceptors.request.use(function (config) {
  // set csrf token each time in case rails might be configured
  // with per request csrf token.
  config.headers['X-CSRF-Token'] = csrf_token()

  return config
})

export default HTTPFetch

import React from 'react'

type Props = {
  content: string
  type: string
}

// this are the current flash  styles/types defined in the stylesheet
const flash_styles = {
  danger: 'flash flash-danger',
  error: 'flash flash-error',
  warning: 'flash flash-warning',
  success: 'flash flash-success',
  notice: 'flash flash-notice'
}

const fallback = flash_styles.notice

const FlashMessage = (props: Props): JSX.Element | null => {
  if (!props.content) {
    return null
  }

  return (
    <div className={flash_styles[props.type] || fallback}>{props.content}</div>
  )
}

export default FlashMessage

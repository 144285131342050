import React from 'react'

type Props = {
  id?: string
  action?: string
  method?: 'get' | 'post'
  class_name?: string
  hidden?: boolean
  disabled?: boolean
  onSubmit?: (event: any) => void
  children?: any
}

const SimpleForm = (props: Props): JSX.Element | null => {
  if (props.hidden) {
    return null
  }

  return (
    <form
      id={props.id}
      action={props.action}
      method={props.method}
      className={props.class_name}
      onSubmit={props.onSubmit}
      acceptCharset="utf-8"
      autoComplete="off"
    >
      <fieldset disabled={props.disabled} style={{ display: 'contents' }}>
        {props.children}
      </fieldset>
    </form>
  )
}

export default SimpleForm

import React from 'react'

type Props = {
  hidden: boolean
}

const BrandLoginLogo = (props: Props): JSX.Element => {
  if (props.hidden) {
    return <></>
  }

  return (
    <div className="logo">
      {/* login page logo if enabled */}
    </div>
  )
}

export default BrandLoginLogo
